import React, { ReactElement } from "react";

export default function AdvancedCalculationsIcon(): ReactElement {
    return (
        <svg
            width="114"
            height="104"
            viewBox="0 0 114 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="63.5" cy="57.5" r="46.5" fill="#DCF6EA" />
            <g opacity="0.5">
                <circle cx="27" cy="24" r="24" fill="#A0DEAA" />
            </g>
            <circle cx="59" cy="51" r="36" fill="#9AD2B9" fillOpacity="0.5" />
            <path
                d="M64.825 63.3355C62.2308 63.3355 60.7218 60.3142 60.8168 56.4439C60.8168 52.5715 62.2308 49.503 64.778 49.503H64.825C67.7022 49.503 68.7873 52.6185 68.7873 56.3487C68.7873 60.4093 67.5612 63.3366 64.825 63.3366V63.3355ZM64.9725 46.9991H64.9255C60.8223 46.9991 57.52 50.446 57.52 56.5839C57.567 62.7219 60.8223 65.8384 64.6895 65.8384C68.6507 65.8384 72 62.6278 72 56.1595C72 50.3049 69.2178 46.9991 64.9725 46.9991Z"
                fill="black"
            />
            <path
                d="M40.8229 43.7414C40.8229 39.869 42.238 36.8005 44.7852 36.8005H44.8322C47.7094 36.8005 48.7945 39.9171 48.7945 43.6462C48.7945 47.7079 47.5684 50.6352 44.8322 50.6352C42.238 50.6352 40.7289 47.6128 40.8229 43.7436V43.7414ZM52.0017 43.4581C52.0017 37.6024 49.2185 34.2977 44.9742 34.2977H44.9262C40.8229 34.2977 37.5217 37.7446 37.5217 43.8825C37.5687 50.0205 40.8229 53.137 44.6912 53.137C48.6524 53.137 52.0017 49.9264 52.0017 43.4581Z"
                fill="black"
            />
            <path
                d="M62.4198 34.2506L44.3546 66.0746H46.9969L65.061 34.2506H62.4198Z"
                fill="black"
            />
            <path
                d="M55.0424 91H54.9576C44.5142 90.9991 34.4654 87.0054 26.8661 79.8352C19.2667 72.665 14.6907 62.8601 14.0737 52.4252C14.0246 51.592 14 50.776 14 49.9994C14 49.863 14 49.7278 14 49.5926V49.4292C14.1452 38.8631 18.361 28.7613 25.7679 21.2315C33.1748 13.7016 43.2005 9.32541 53.7532 9.01598V9H55.0424C65.4858 9.00085 75.5346 12.9946 83.1339 20.1648C90.7333 27.335 95.3093 37.1399 95.9263 47.5748V47.5748C95.9754 48.408 96 49.224 96 50.0006C96 50.137 96 50.2722 96 50.4074V50.5708C95.8545 61.1373 91.638 71.2393 84.2304 78.769C76.8227 86.2987 66.7962 90.6743 56.2431 90.9828V90.9988H55.0387L55.0424 91ZM54.4997 88.4193H55.0424C65.1298 88.42 74.812 84.4453 81.9941 77.3552C89.1761 70.2652 93.2805 60.6299 93.4193 50.5339V50.3631C93.4193 50.2402 93.4193 50.1174 93.4193 49.9945C93.4193 49.2682 93.3959 48.5026 93.3505 47.7198C92.7742 38.0032 88.5345 28.8678 81.4881 22.1596C74.4417 15.4513 65.114 11.6704 55.3898 11.5807H54.9576C44.8714 11.5816 35.191 15.5569 28.0103 22.6465C20.8296 29.7361 16.7259 39.3702 16.5869 49.4648V49.6356C16.5869 49.7585 16.5869 49.8814 16.5869 50.0043C16.5869 50.7306 16.6102 51.4962 16.6556 52.279C17.2307 61.9754 21.4541 71.0939 28.4758 77.7989C35.4974 84.504 44.7959 88.2979 54.4997 88.4169V88.4193Z"
                fill="black"
            />
        </svg>
    );
}
