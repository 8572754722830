import React, { ReactElement } from "react";
import colors from "@styles/variables/payrollsystemsVariables.module.scss";

import SaveTimeIcon from "../icons/saveTimeIcon";
import CostEffectiveIcon from "../icons/costEffectiveIcon";
import ProductivityIcon from "../icons/productivity";
import AdvancedCalculationsIcon from "../icons/advancedCalculations";
import CustomizableDesignIcon from "../icons/customizableDesign";
import QuickResultsIcon from "../icons/quickResults";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Save Time",
        description:
            "Time is vital to businesses. A payroll software system will streamline your processes so you don't have to worry about manually entering long pay stubs and payroll calculations.",
        icon: <SaveTimeIcon />,
    },
    {
        title: "Cost Effective",
        description:
            "Payroll systems will help you save significant amounts of money otherwise spent on manual payroll processing. Your business can cut costs with professional services that can help you control payroll and increase your returns.",
        icon: <CostEffectiveIcon />,
    },
    {
        title: "Boost Employee Productivity",
        description:
            "A traditional payroll management system may delay payments sometimes. But you can count on your payroll software system to satisfy employees and meet their expectations of getting paid on time.",
        icon: <ProductivityIcon />,
    },
    {
        title: "Advanced Calculations",
        description:
            "Traditional methods of calculating, like adding up numbers on your fingers or using an outdated calculator are no longer sufficient or practical. Your payroll system will do the job for you.",
        icon: <AdvancedCalculationsIcon />,
    },
    {
        title: "Customizable Design",
        description:
            "You can completely own the software system and personalize all applications to suit your business needs.",
        icon: <CustomizableDesignIcon />,
    },
    {
        title: "Quick Results",
        description:
            "Thanks to the system’s exceptional speed, your payroll software will double the operating speed and give you access to instant results.",
        icon: <QuickResultsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Benefits of Payroll Systems"
            classes={{
                mainTitleClasses:
                    "text-center text-xl lg:text-2xl mb-14 font-bold",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: " children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                descriptionColor: colors.whyUsBriefColor,
            }}
        />
    );
}
