import React, { ReactElement } from "react";

export default function SaveTimeIcon(): ReactElement {
    return (
        <svg
            width="114"
            height="104"
            viewBox="0 0 114 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="47.5" cy="48.5" r="46.5" fill="#DCF6EA" />
            <g opacity="0.5">
                <circle cx="77" cy="78" r="24" fill="#A0DEAA" />
            </g>
            <circle cx="76" cy="38" r="36" fill="#9AD2B9" fillOpacity="0.5" />
            <path
                d="M47.5014 86.9999C26.2714 86.9999 9 69.728 9 48.4996C9.00162 39.5164 12.1476 30.8172 17.8923 23.911C18.0339 23.7352 18.2091 23.5894 18.4076 23.4821C18.6061 23.3748 18.8241 23.308 19.0486 23.2858C19.2732 23.2636 19.5 23.2862 19.7157 23.3525C19.9315 23.4188 20.1318 23.5274 20.3052 23.672C20.4785 23.8165 20.6213 23.9941 20.7253 24.1944C20.8293 24.3947 20.8924 24.6137 20.9109 24.8386C20.9294 25.0635 20.9029 25.2899 20.833 25.5045C20.7631 25.7191 20.6512 25.9176 20.5037 26.0885C15.2677 32.3832 12.3997 40.3119 12.397 48.4996C12.397 67.8544 28.1457 83.5993 47.5014 83.5993C49.5408 83.6035 51.5759 83.4127 53.5789 83.0296C53.7981 82.9875 54.0234 82.989 54.242 83.034C54.4607 83.079 54.6683 83.1666 54.853 83.2919C55.0378 83.4171 55.1961 83.5775 55.3188 83.7639C55.4416 83.9503 55.5264 84.159 55.5685 84.3782C55.6106 84.5974 55.6091 84.8228 55.5641 85.0414C55.5191 85.26 55.4315 85.4676 55.3062 85.6524C55.181 85.8371 55.0206 85.9954 54.8342 86.1181C54.6478 86.2409 54.439 86.3257 54.2198 86.3678C52.0056 86.7923 49.7559 87.0039 47.5014 86.9999V86.9999ZM83.7049 56.5989C83.5976 56.5985 83.4905 56.5889 83.3848 56.5703C82.9415 56.4859 82.5498 56.2289 82.2959 55.8558C82.042 55.4827 81.9467 55.024 82.031 54.5807C82.4139 52.5764 82.6054 50.5402 82.6029 48.4996C82.6029 29.1456 66.8542 13.3999 47.5014 13.3999C39.3125 13.4029 31.3828 16.2708 25.0864 21.5066C24.9155 21.654 24.717 21.7659 24.5024 21.8358C24.2878 21.9057 24.0615 21.9322 23.8365 21.9137C23.6116 21.8952 23.3926 21.8322 23.1923 21.7282C22.992 21.6242 22.8144 21.4813 22.6699 21.308C22.5253 21.1347 22.4168 20.9343 22.3505 20.7186C22.2842 20.5028 22.2615 20.2761 22.2837 20.0515C22.306 19.8269 22.3727 19.609 22.48 19.4105C22.5874 19.2119 22.7332 19.0367 22.9089 18.8951C29.8167 13.1503 38.5169 10.0034 47.5014 10C68.7315 10 85.9999 27.272 85.9999 48.4996C86.0046 50.7539 85.7946 53.0035 85.3729 55.2179C85.2987 55.6061 85.0916 55.9563 84.7872 56.2083C84.4828 56.4603 84.1001 56.5984 83.7049 56.5989V56.5989Z"
                fill="black"
            />
            <path
                d="M48.0051 54C47.016 54.001 46.0488 53.7086 45.2259 53.1598C44.403 52.6111 43.7613 51.8305 43.3821 50.917C43.0029 50.0034 42.9031 48.9979 43.0955 48.0277C43.2878 47.0575 43.7636 46.1661 44.4626 45.4663C45.1617 44.7665 46.0526 44.2898 47.0227 44.0965C47.9927 43.9031 48.9983 44.0018 49.9122 44.3801C50.8262 44.7584 51.6074 45.3993 52.157 46.2216C52.7066 47.044 53 48.0109 53 49C52.9988 50.3248 52.4724 51.5951 51.5361 52.5324C50.5997 53.4697 49.33 53.9975 48.0051 54V54ZM48.0051 47.4006C47.6886 47.4003 47.379 47.4939 47.1157 47.6696C46.8523 47.8453 46.647 48.0951 46.5257 48.3875C46.4044 48.6799 46.3725 49.0018 46.4342 49.3123C46.4958 49.6228 46.6482 49.908 46.872 50.1319C47.0958 50.3558 47.3809 50.5083 47.6914 50.5701C48.0019 50.6319 48.3237 50.6002 48.6162 50.479C48.9086 50.3578 49.1586 50.1526 49.3344 49.8893C49.5102 49.6261 49.6039 49.3166 49.6038 49C49.5979 48.5778 49.4276 48.1745 49.1291 47.8758C48.8306 47.5772 48.4274 47.4067 48.0051 47.4006V47.4006Z"
                fill="black"
            />
            <path
                d="M47.4997 47C47.3026 47.0001 47.1074 46.9569 46.9254 46.873C46.7433 46.7891 46.5779 46.666 46.4386 46.5109C46.2993 46.3557 46.1889 46.1715 46.1137 45.9689C46.0385 45.7662 46 45.549 46.0003 45.3297V24.7035C45.9962 24.4815 46.0319 24.2608 46.1054 24.0543C46.1789 23.8478 46.2887 23.6598 46.4284 23.5011C46.568 23.3425 46.7348 23.2164 46.9188 23.1304C47.1028 23.0443 47.3004 23 47.5 23C47.6996 23 47.8972 23.0443 48.0812 23.1304C48.2652 23.2164 48.432 23.3425 48.5716 23.5011C48.7113 23.6598 48.8211 23.8478 48.8946 24.0543C48.9681 24.2608 49.0038 24.4815 48.9997 24.7035V45.3297C48.9999 45.549 48.9613 45.7662 48.886 45.9689C48.8108 46.1716 48.7003 46.3558 48.561 46.5109C48.4217 46.666 48.2562 46.7891 48.0741 46.873C47.892 46.957 47.6968 47.0001 47.4997 47V47Z"
                fill="black"
            />
            <path
                d="M34.8044 62C34.4475 61.9998 34.0986 61.9014 33.8019 61.7171C33.5052 61.5329 33.2739 61.2711 33.1374 60.9649C33.0008 60.6587 32.9651 60.3218 33.0346 59.9967C33.1042 59.6716 33.276 59.3729 33.5283 59.1385L43.9359 49.4746C44.276 49.1674 44.7327 48.997 45.2072 49C45.6816 49.0031 46.1357 49.1795 46.4712 49.491C46.8067 49.8026 46.9966 50.2242 47 50.6648C47.0033 51.1054 46.8197 51.5295 46.4889 51.8453L36.0813 61.5092C35.914 61.6654 35.715 61.7892 35.4958 61.8734C35.2766 61.9577 35.0416 62.0007 34.8044 62Z"
                fill="black"
            />
            <path
                d="M47.4997 20C47.3026 20.0001 47.1074 19.9566 46.9254 19.8719C46.7433 19.7872 46.5779 19.663 46.4386 19.5065C46.2993 19.3499 46.1889 19.1641 46.1137 18.9596C46.0385 18.7551 46 18.536 46.0003 18.3147V17.7188C45.9962 17.4948 46.0319 17.2721 46.1054 17.0638C46.1789 16.8554 46.2887 16.6657 46.4284 16.5056C46.568 16.3455 46.7348 16.2184 46.9188 16.1315C47.1028 16.0447 47.3004 16 47.5 16C47.6996 16 47.8972 16.0447 48.0812 16.1315C48.2652 16.2184 48.432 16.3455 48.5716 16.5056C48.7113 16.6657 48.8211 16.8554 48.8946 17.0638C48.9681 17.2721 49.0038 17.4948 48.9997 17.7188V18.3147C48.9999 18.536 48.9613 18.7552 48.886 18.9597C48.8108 19.1642 48.7003 19.35 48.561 19.5065C48.4217 19.6631 48.2562 19.7872 48.0741 19.8719C47.892 19.9566 47.6968 20.0001 47.4997 20V20Z"
                fill="black"
            />
            <path
                d="M47.4997 81C47.3026 81.0001 47.1075 80.9576 46.9255 80.875C46.7435 80.7924 46.5781 80.6713 46.4388 80.5186C46.2996 80.3658 46.1892 80.1846 46.1139 79.985C46.0387 79.7855 46.0001 79.5717 46.0003 79.3559V78.6776C45.9962 78.4589 46.0319 78.2416 46.1054 78.0383C46.1789 77.8349 46.2887 77.6497 46.4284 77.4935C46.568 77.3373 46.7348 77.2131 46.9188 77.1284C47.1028 77.0436 47.3004 77 47.5 77C47.6996 77 47.8972 77.0436 48.0812 77.1284C48.2652 77.2131 48.432 77.3373 48.5716 77.4935C48.7113 77.6497 48.8211 77.8349 48.8946 78.0383C48.9681 78.2416 49.0038 78.4589 48.9997 78.6776V79.3559C48.9998 79.5718 48.9612 79.7856 48.8859 79.9851C48.8105 80.1846 48.7001 80.3659 48.5608 80.5186C48.4214 80.6713 48.256 80.7924 48.074 80.875C47.8919 80.9576 47.6967 81.0001 47.4997 81V81Z"
                fill="black"
            />
            <path
                d="M78.347 50H77.653C77.2115 49.9918 76.7911 49.8302 76.4821 49.5498C76.1731 49.2694 76 48.8925 76 48.5C76 48.1075 76.1731 47.7306 76.4821 47.4502C76.7911 47.1698 77.2115 47.0082 77.653 47H78.347C78.7885 47.0082 79.2089 47.1698 79.5179 47.4502C79.8269 47.7306 80 48.1075 80 48.5C80 48.8925 79.8269 49.2694 79.5179 49.5498C79.2089 49.8302 78.7885 49.9918 78.347 50V50Z"
                fill="black"
            />
            <path
                d="M17.3075 50H16.6947C16.2421 49.9918 15.8111 49.8302 15.4943 49.5498C15.1774 49.2694 15 48.8925 15 48.5C15 48.1075 15.1774 47.7306 15.4943 47.4502C15.8111 47.1698 16.2421 47.0082 16.6947 47H17.3053C17.7579 47.0082 18.1889 47.1698 18.5057 47.4502C18.8226 47.7306 19 48.1075 19 48.5C19 48.8925 18.8226 49.2694 18.5057 49.5498C18.1889 49.8302 17.7579 49.9918 17.3053 50H17.3075Z"
                fill="black"
            />
            <path
                d="M76.9985 78.9974C73.0734 78.9974 70 76.8022 70 73.9997C70 71.243 73.1374 69 76.9985 69C80.927 69 83.9997 71.1971 83.9997 73.9997C84.0039 74.2014 83.9672 74.4019 83.8915 74.5895C83.8158 74.7771 83.7027 74.948 83.5589 75.0922C83.415 75.2363 83.2433 75.3508 83.0538 75.429C82.8644 75.5072 82.6609 75.5475 82.4553 75.5475C82.2498 75.5475 82.0463 75.5072 81.8568 75.429C81.6673 75.3508 81.4956 75.2363 81.3518 75.0922C81.2079 74.948 81.0949 74.7771 81.0192 74.5895C80.9435 74.4019 80.9067 74.2014 80.911 73.9997C80.911 73.1786 79.423 72.0339 76.9985 72.0339C74.6488 72.0339 73.0887 73.2134 73.0887 73.9997C73.0887 74.9269 74.7616 75.9655 76.9985 75.9655C77.4029 75.9737 77.7879 76.1372 78.0709 76.4208C78.3539 76.7045 78.5124 77.0857 78.5124 77.4827C78.5124 77.8798 78.3539 78.261 78.0709 78.5446C77.7879 78.8283 77.4029 78.9918 76.9985 79V78.9974Z"
                fill="black"
            />
            <path
                d="M76.9988 87C73.1391 87 70.0003 84.7773 70.0003 82.0445C69.9961 81.8445 70.0328 81.6456 70.1085 81.4596C70.1842 81.2736 70.2973 81.1042 70.4411 80.9612C70.585 80.8183 70.7567 80.7048 70.9462 80.6272C71.1356 80.5497 71.3391 80.5098 71.5447 80.5098C71.7502 80.5098 71.9537 80.5497 72.1432 80.6272C72.3327 80.7048 72.5044 80.8183 72.6482 80.9612C72.7921 81.1042 72.9051 81.2736 72.9808 81.4596C73.0565 81.6456 73.0933 81.8445 73.089 82.0445C73.089 82.8183 74.6491 83.9937 76.9988 83.9937C79.4233 83.9937 80.9113 82.8593 80.9113 82.0445C80.9113 81.0823 79.3045 80.0089 76.9988 80.0089C76.5944 80.0007 76.2095 79.8386 75.9264 79.5574C75.6434 79.2761 75.4849 78.8981 75.4849 78.5045C75.4849 78.1108 75.6434 77.7328 75.9264 77.4515C76.2095 77.1703 76.5944 77.0082 76.9988 77C80.9273 77 84 79.2162 84 82.0445C83.9987 84.8234 80.928 87 76.9988 87Z"
                fill="black"
            />
            <path
                d="M77.0002 72C76.8688 72.0001 76.7387 71.9479 76.6174 71.8463C76.496 71.7448 76.3857 71.5959 76.2928 71.4082C76.2 71.2204 76.1263 70.9975 76.0761 70.7522C76.0259 70.507 76.0001 70.2441 76.0002 69.9786V66.0625C75.9974 65.7937 76.0213 65.5265 76.0703 65.2765C76.1193 65.0265 76.1925 64.7988 76.2856 64.6067C76.3787 64.4146 76.4898 64.262 76.6125 64.1578C76.7352 64.0537 76.8669 64 77 64C77.1331 64 77.2648 64.0537 77.3875 64.1578C77.5102 64.262 77.6213 64.4146 77.7144 64.6067C77.8075 64.7988 77.8807 65.0265 77.9297 65.2765C77.9787 65.5265 78.0026 65.7937 77.9998 66.0625V69.9856C77.9992 70.5204 77.8936 71.0329 77.7062 71.4106C77.5188 71.7882 77.2649 72.0002 77.0002 72V72Z"
                fill="black"
            />
            <path
                d="M77.0002 92C76.8688 92.0001 76.7387 91.948 76.6173 91.8465C76.4959 91.7451 76.3856 91.5964 76.2927 91.4089C76.1998 91.2214 76.1262 90.9988 76.076 90.7538C76.0258 90.5089 76 90.2463 76.0002 89.9813V86.0589C75.9974 85.7906 76.0213 85.5238 76.0703 85.2743C76.1193 85.0247 76.1925 84.7974 76.2856 84.6057C76.3787 84.4139 76.4898 84.2616 76.6125 84.1576C76.7352 84.0536 76.8669 84 77 84C77.1331 84 77.2648 84.0536 77.3875 84.1576C77.5102 84.2616 77.6213 84.4139 77.7144 84.6057C77.8075 84.7974 77.8807 85.0247 77.9297 85.2743C77.9787 85.5238 78.0026 85.7906 77.9998 86.0589V89.9813C78 90.2463 77.9743 90.5088 77.9242 90.7538C77.874 90.9987 77.8004 91.2213 77.7076 91.4088C77.6148 91.5963 77.5045 91.7451 77.3831 91.8465C77.2617 91.948 77.1316 92.0001 77.0002 92V92Z"
                fill="black"
            />
            <path
                d="M76.9502 98C65.9491 98 57 89.006 57 77.9513C57 66.9507 65.9469 58 76.9502 58C81.047 58 85.053 59.2266 88.2291 61.4454C88.4091 61.5712 88.5626 61.7313 88.6808 61.9165C88.7989 62.1017 88.8794 62.3084 88.9177 62.5247C88.956 62.741 88.9513 62.9627 88.9039 63.1773C88.8565 63.3918 88.7674 63.5948 88.6415 63.7748C88.5156 63.9549 88.3555 64.1083 88.1703 64.2265C87.9851 64.3447 87.7785 64.4252 87.5621 64.4635C87.3458 64.5018 87.1241 64.4971 86.9096 64.4497C86.6951 64.4023 86.4921 64.3131 86.312 64.1872C83.695 62.3538 80.3703 61.3451 76.9502 61.3451C67.7912 61.3451 60.3385 68.7957 60.3385 77.9542C60.3385 87.1668 67.7912 94.6614 76.9502 94.6614C86.1634 94.6614 93.66 87.1668 93.66 77.9542C93.66 74.7152 92.6102 71.3838 90.7018 68.5742C90.5786 68.3928 90.4924 68.1889 90.448 67.9741C90.4036 67.7594 90.402 67.538 90.4431 67.3226C90.4843 67.1073 90.5675 66.9021 90.6879 66.7189C90.8084 66.5357 90.9638 66.378 91.1452 66.2548C91.3266 66.1316 91.5305 66.0454 91.7452 66.001C91.9599 65.9566 92.1813 65.9549 92.3967 65.9961C92.612 66.0373 92.8172 66.1205 93.0004 66.2409C93.1836 66.3614 93.3413 66.5168 93.4645 66.6982C95.7438 70.0591 97 74.0579 97 77.9542C96.9986 89.006 88.0054 98 76.9502 98Z"
                fill="black"
            />
        </svg>
    );
}
