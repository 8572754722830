import React, { ReactElement } from "react";

export default function ProductivityIcon(): ReactElement {
    return (
        <svg
            width="114"
            height="104"
            viewBox="0 0 114 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="46.5" cy="54.5" r="46.5" fill="#DCF6EA" />
            <g opacity="0.5">
                <circle cx="89" cy="26" r="24" fill="#A0DEAA" />
            </g>
            <circle cx="47" cy="54" r="36" fill="#9AD2B9" fillOpacity="0.5" />
            <path
                d="M82.3485 66.665H74.7358C74.4993 66.665 74.2725 66.5714 74.1052 66.4049C73.938 66.2383 73.844 66.0124 73.844 65.7768V40.8436H72.4017C72.2243 40.8441 72.0508 40.7919 71.9034 40.6937C71.7559 40.5956 71.6412 40.4558 71.5739 40.2923C71.5066 40.1289 71.4898 39.9492 71.5256 39.7761C71.5614 39.6031 71.6482 39.4446 71.7749 39.321L77.921 33.279C78.088 33.1147 78.3132 33.0226 78.5479 33.0226C78.7826 33.0226 79.0078 33.1147 79.1747 33.279L85.3107 39.3248C85.4374 39.4485 85.5242 39.6069 85.56 39.7799C85.5958 39.953 85.579 40.1327 85.5117 40.2961C85.4444 40.4596 85.3297 40.5994 85.1822 40.6976C85.0348 40.7957 84.8612 40.8479 84.6838 40.8474H83.2403V65.7768C83.2403 66.0124 83.1463 66.2383 82.9791 66.4049C82.8118 66.5714 82.585 66.665 82.3485 66.665V66.665ZM75.6264 64.8887H81.4566V39.9554C81.4566 39.7199 81.5506 39.4939 81.7178 39.3274C81.8851 39.1608 82.1119 39.0672 82.3485 39.0672H82.5077L78.539 35.1593L74.5702 39.0672H74.7358C74.9724 39.0672 75.1992 39.1608 75.3665 39.3274C75.5337 39.4939 75.6277 39.7199 75.6277 39.9554L75.6264 64.8887Z"
                fill="black"
            />
            <path
                d="M67.7526 66.665H60.1412C59.9047 66.665 59.6779 66.5714 59.5106 66.4048C59.3433 66.2383 59.2494 66.0124 59.2494 65.7768V44.7997H57.8071C57.6297 44.8002 57.4562 44.748 57.3087 44.6498C57.1613 44.5516 57.0466 44.4119 56.9793 44.2484C56.912 44.085 56.8951 43.9053 56.931 43.7322C56.9668 43.5592 57.0536 43.4007 57.1803 43.2771L63.3201 37.2325C63.487 37.0683 63.7122 36.9761 63.9469 36.9761C64.1816 36.9761 64.4068 37.0683 64.5737 37.2325L70.7135 43.2771C70.8402 43.4007 70.927 43.5592 70.9629 43.7322C70.9987 43.9053 70.9818 44.085 70.9145 44.2484C70.8473 44.4119 70.7325 44.5516 70.5851 44.6498C70.4376 44.748 70.2641 44.8002 70.0867 44.7997H68.6444V65.7768C68.6444 66.0124 68.5505 66.2383 68.3832 66.4048C68.216 66.5714 67.9891 66.665 67.7526 66.665V66.665ZM61.0331 64.8886H66.8607V43.9115C66.8607 43.6759 66.9547 43.45 67.1219 43.2835C67.2892 43.1169 67.516 43.0233 67.7526 43.0233H67.9156L63.9469 39.1154L59.9769 43.0233H60.1412C60.3778 43.0233 60.6046 43.1169 60.7719 43.2835C60.9391 43.45 61.0331 43.6759 61.0331 43.9115V64.8886Z"
                fill="black"
            />
            <path
                d="M53.1592 66.665H45.5454C45.3088 66.665 45.082 66.5714 44.9147 66.4049C44.7475 66.2383 44.6535 66.0124 44.6535 65.7768V48.7546H43.2125C43.0351 48.7551 42.8616 48.7029 42.7141 48.6047C42.5667 48.5066 42.452 48.3668 42.3847 48.2033C42.3174 48.0399 42.3005 47.8602 42.3364 47.6871C42.3722 47.5141 42.459 47.3556 42.5857 47.232L48.7242 41.1874C48.8912 41.0228 49.1167 40.9304 49.3517 40.9304C49.5867 40.9304 49.8122 41.0228 49.9791 41.1874L56.1189 47.232C56.2457 47.3557 56.3325 47.5143 56.3683 47.6874C56.4041 47.8606 56.3872 48.0404 56.3197 48.2039C56.2523 48.3674 56.1373 48.5072 55.9897 48.6053C55.842 48.7034 55.6683 48.7554 55.4908 48.7546H54.0511V65.7768C54.0511 65.8935 54.028 66.009 53.9832 66.1167C53.9384 66.2245 53.8727 66.3224 53.7899 66.4049C53.7071 66.4873 53.6087 66.5528 53.5005 66.5974C53.3923 66.642 53.2764 66.665 53.1592 66.665V66.665ZM46.4372 64.8887H52.2674V47.8664C52.2674 47.6309 52.3614 47.405 52.5286 47.2384C52.6959 47.0718 52.9227 46.9783 53.1592 46.9783H53.321L49.3523 43.0703L45.3823 46.9783H45.5454C45.7819 46.9783 46.0087 47.0718 46.176 47.2384C46.3433 47.405 46.4372 47.6309 46.4372 47.8664V64.8887Z"
                fill="black"
            />
            <path
                d="M39.2019 66.6649H31.588C31.3515 66.6649 31.1246 66.5714 30.9574 66.4048C30.7901 66.2382 30.6962 66.0123 30.6962 65.7768V52.4657H29.2513C29.074 52.4663 28.9004 52.4141 28.753 52.3159C28.6055 52.2177 28.4908 52.078 28.4235 51.9145C28.3562 51.7511 28.3394 51.5713 28.3752 51.3983C28.411 51.2253 28.4978 51.0668 28.6245 50.9432L34.763 44.8986C34.9299 44.7343 35.1552 44.6422 35.3899 44.6422C35.6245 44.6422 35.8498 44.7343 36.0167 44.8986L42.1577 50.9432C42.2845 51.0668 42.3713 51.2253 42.4071 51.3983C42.4429 51.5713 42.4261 51.7511 42.3588 51.9145C42.2915 52.078 42.1768 52.2177 42.0293 52.3159C41.8818 52.4141 41.7083 52.4663 41.5309 52.4657H40.0937V65.7768C40.0937 66.0123 39.9998 66.2382 39.8325 66.4048C39.6653 66.5714 39.4384 66.6649 39.2019 66.6649V66.6649ZM32.4799 64.8886H38.31V51.5776C38.31 51.342 38.404 51.1161 38.5713 50.9496C38.7385 50.783 38.9654 50.6894 39.2019 50.6894H39.3637L35.3937 46.7815L31.4249 50.6894H31.588C31.8245 50.6894 32.0514 50.783 32.2186 50.9496C32.3859 51.1161 32.4799 51.342 32.4799 51.5776V64.8886Z"
                fill="black"
            />
            <path
                d="M103.423 46.1142C103.219 45.9119 102.942 45.7983 102.654 45.7983C102.366 45.7983 102.089 45.9119 101.885 46.1142L98.3304 49.6541C97.7283 39.2035 93.1354 29.3779 85.493 22.191C77.8506 15.0042 67.7374 11.0003 57.2261 11H57.0006H56.7738C47.3993 11.1034 38.3408 14.389 31.0954 20.3139C23.85 26.2387 18.8514 34.4482 16.9258 43.5854L19.0917 43.8189C21.047 34.7198 26.2364 26.6301 33.7049 21.0386C41.1735 15.447 50.4187 12.7298 59.7391 13.387C69.0595 14.0442 77.828 18.0316 84.4309 24.6153C91.0338 31.1991 95.0269 39.9363 95.6752 49.2189L92.5601 46.1142C92.3562 45.9111 92.0796 45.797 91.7912 45.797C91.5028 45.797 91.2262 45.9111 91.0223 46.1142C90.8184 46.3172 90.7038 46.5927 90.7038 46.8799C90.7038 47.1671 90.8184 47.4425 91.0223 47.6456L95.4179 52.0242L95.772 52.377L96.4537 53.0545C96.6476 53.2465 96.9069 53.3589 97.1802 53.3695C97.4535 53.3801 97.7207 53.288 97.9291 53.1116C97.9507 53.0926 97.9711 53.0748 97.9915 53.0545L98.6757 52.3808L103.423 47.6519C103.524 47.5511 103.605 47.4312 103.66 47.2993C103.715 47.1673 103.743 47.0259 103.743 46.883C103.743 46.7402 103.715 46.5988 103.66 46.4668C103.605 46.3349 103.524 46.215 103.423 46.1142V46.1142Z"
                fill="black"
            />
            <path
                d="M84.3538 79.466C79.0872 84.6875 72.4247 88.2916 65.1596 89.8492C57.8944 91.4067 50.3329 90.852 43.3753 88.2511C36.4178 85.6503 30.3575 81.1129 25.916 75.1792C21.4745 69.2455 18.8391 62.1657 18.3235 54.7827L21.4412 57.8875C21.5422 57.988 21.662 58.0678 21.794 58.1222C21.9259 58.1766 22.0673 58.2046 22.2101 58.2046C22.3529 58.2046 22.4943 58.1766 22.6262 58.1222C22.7582 58.0678 22.878 57.988 22.979 57.8875C23.08 57.7869 23.1601 57.6675 23.2147 57.5361C23.2694 57.4048 23.2975 57.2639 23.2975 57.1217C23.2975 56.9795 23.2694 56.8387 23.2147 56.7073C23.1601 56.576 23.08 56.4566 22.979 56.356L18.228 51.6196L17.5476 50.942C17.3537 50.75 17.0944 50.6376 16.8211 50.627C16.5478 50.6165 16.2806 50.7085 16.0722 50.8849C16.0506 50.904 16.0289 50.9217 16.0085 50.942L10.5771 56.3509C10.3725 56.554 10.2573 56.8297 10.2568 57.1174C10.2564 57.4051 10.3707 57.6812 10.5746 57.8849C10.7785 58.0887 11.0554 58.2034 11.3442 58.2039C11.6331 58.2044 11.9103 58.0906 12.1149 57.8875L15.6696 54.3475C16.2717 64.7977 20.8642 74.6229 28.5061 81.8097C36.1479 88.9965 46.2605 93.0007 56.7713 93.0016H56.9969H57.2236C66.5422 92.8998 75.5506 89.6532 82.7764 83.7925C90.0022 77.9317 95.018 69.8033 97.0042 60.7359L94.8459 60.4669C93.2343 67.6649 89.5946 74.2557 84.3538 79.466V79.466Z"
                fill="black"
            />
        </svg>
    );
}
